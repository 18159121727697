<template>
  <el-card shadow="never">
    <div slot="header">
      <span class="iconfont icon-SystemInformation">&nbsp;系统资讯</span>
    </div>
    <div class="card_header_flex">
      <div>
        <el-button type="primary" size="small" @click="dialogAddNews=true">新增资讯</el-button>
      </div>
    </div>
    <el-table
      :data="list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
      size="mini"
    >
      <el-table-column label="序号" prop="newsId" width="100"></el-table-column>
      <el-table-column label="标题" prop="newsTitle" show-overflow-tooltip></el-table-column>
      <el-table-column label="内容" prop="newsContent" show-overflow-tooltip></el-table-column>
      <el-table-column label="发布类型" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.newsType=='background'?'后台':'小程序'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="newsState" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.newsState=='publish'?'发布':'草稿'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="openEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="mini" @click="delNews(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page_right">
      <el-pagination
        background
        layout="total,prev, pager, next ,jumper"
        :total="list.length"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <!-- 添加资讯 -->
    <el-dialog
      :visible.sync="dialogAddNews"
      :close-on-click-modal="false"
      width="40%"
      @closed="closeDialogAddNews"
    >
      <span slot="title">添加资讯</span>
      <el-form
        :model="form"
        ref="form"
        label-width="80px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="标题"
          prop="newsTitle"
          :rules="[{ required: true, message: '请输入资讯标题', trigger: 'blur' }]"
        >
          <el-input v-model="form.newsTitle" placeholder="请输入资讯标题"></el-input>
        </el-form-item>
        <el-form-item
          label="内容"
          prop="newsContent"
          :rules="[{ required: true, message: '请输入资讯内容', trigger: 'blur' }]"
        >
          <el-input type="textarea" v-model="form.newsContent" :rows="5" placeholder="请输入资讯内容"></el-input>
        </el-form-item>

        <el-form-item
          label="外链地址"
          prop="outerUrl"
          :rules="[{ required: true, message: '请输入外链地址', trigger: 'blur' }]"
          v-if="form.isOuter=='on'"
        >
          <el-input v-model="form.outerUrl" placeholder="请输入外链地址"></el-input>
        </el-form-item>

        <el-form-item label="状态">
          <el-radio-group v-model="form.newsState" size="mini">
            <el-radio-button label="publish">发布</el-radio-button>
            <el-radio-button label="draft">草稿</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="类型">
          <el-radio-group v-model="form.newsType" size="mini">
            <el-radio-button label="background">后台</el-radio-button>
            <el-radio-button label="mini_program">小程序</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="开启外链" v-if="form.newsType=='mini_program'">
          <el-radio-group v-model="form.isOuter" size="mini">
            <el-radio-button label="on">开启</el-radio-button>
            <el-radio-button label="off">关闭</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddNews=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddNews">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改资讯 -->
    <el-dialog
      :visible.sync="dialogEditNews"
      :close-on-click-modal="false"
      width="40%"
    >
      <span slot="title">编辑资讯</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="80px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="标题"
          prop="newsTitle"
          :rules="[{ required: true, message: '请输入资讯标题', trigger: 'blur' }]"
        >
          <el-input v-model="editForm.newsTitle" placeholder="请输入资讯标题"></el-input>
        </el-form-item>
        <el-form-item
          label="内容"
          prop="newsContent"
          :rules="[{ required: true, message: '请输入资讯内容', trigger: 'blur' }]"
        >
          <el-input type="textarea" v-model="editForm.newsContent" :rows="5" placeholder="请输入资讯内容"></el-input>
        </el-form-item>
        <el-form-item label="外链地址" prop="outerUrl"  :rules="[{ required: true, message: '请输入外链地址', trigger: 'blur' }]" v-if="editForm.isOuter=='on'">
          <el-input v-model="editForm.outerUrl" placeholder="请输入外链地址"></el-input>
        </el-form-item>

        <el-form-item label="状态">
          <el-radio-group v-model="editForm.newsState" size="mini">
            <el-radio-button label="publish">发布</el-radio-button>
            <el-radio-button label="draft">草稿</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="类型">
          <el-radio-group v-model="editForm.newsType" size="mini">
            <el-radio-button label="background">后台</el-radio-button>
            <el-radio-button label="mini_program">小程序</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="开启外链" v-if="editForm.newsType=='mini_program'">
          <el-radio-group v-model="editForm.isOuter" size="mini">
            <el-radio-button label="on">开启</el-radio-button>
            <el-radio-button label="off">关闭</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditNews=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditNews">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>
<script>
import {
  getNewsListApi,
  addNewsListApi,
  editNewsListApi,
  delNewsListApi
} from "../../api/data";
export default {
  data() {
    return {
      list: [],
      dialogAddNews: false, //添加窗口
      dialogEditNews: false, //修改窗口
      form: {
        newsTitle: "",
        newsContent: "",
        newsState: "publish",
        newsType: "background",
        isOuter: "off",
        outerUrl: ""
      },
      editForm: {},
      currentPage: 1, //当前页
      pageSize: 10 //一页显示多少条
    };
  },
  created() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      getNewsListApi().then(res => {
        if (res) this.list = res.data;
      });
    },
    //添加资讯
    onSubmitAddNews() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          addNewsListApi(this.form).then(res => {
            this.$message.success(res.msg);
            this.initLoad();
            this.dialogAddNews = false;
          });
        } else {
          return false;
        }
      });
    },
    //添加关闭事件
    closeDialogAddNews() {
      this.$refs["form"].resetFields();
    },
    //修改系统资讯
    onSubmitEditNews() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          editNewsListApi(this.editForm).then(res => {
            this.$message.success(res.msg);
            this.initLoad();
            this.dialogEditNews = false;
          });
        } else {
          return false;
        }
      });
    },

    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },
    //打开资讯窗口
    openEdit(obj) {
      this.dialogEditNews = true;
      this.editForm = { ...obj };
    },
    //删除资讯
    delNews(obj) {
      this.$confirm("此操作将永久删除该资讯, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delNewsListApi({ newsId: obj.newsId }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    }
  }
};
</script>
<style lang='scss'>
</style>